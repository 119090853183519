import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { contentPageWithSubNavigationPreview as PREVIEW_QUERY } from "../preview-queries/contentPageWithSubNavigationPreview"

import { ResponsiveContext } from "grommet"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Subnavigation from "../components/Shared/Subnavigation"
import Article from "../components/Shared/Article"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import Content from "../components/Shared/Content"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import ShareLinks from "../components/Shared/ShareLinks"
import ArticleHeader from "../components/Shared/ArticleHeader"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"
import Newsletter from "../components/Newsletter/Newsletter"

const TemplateNewsletterSignup = (props) => {
    const page = getContent(props, "page")

    const venue = venueForPage({ page: page, menus: props.data.menus })
    const venueChildPage = page.ancestors?.nodes && page.ancestors.nodes.slice(-1)[0].pageTemplate === "home-page"
    const venueFirstChildPage = page.parent && page.parent.pageTemplate === "home-page"
    const ancestorsCount = page.ancestors && page.ancestors.length

    let superTitle
    if (venueChildPage && ancestorsCount === 2) {
        superTitle = page.ancestors.nodes.slice(-2)[0].title
    } else if (!venueChildPage && page.ancestors?.nodes) {
        superTitle = page.ancestors.nodes.slice(-1)[0].title
    }

    const subNavigation = <Subnavigation sectionTitle={superTitle} page={page} />

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaSmall title={superTitle} image={page.image.image}></HeroAreaSmall>

            <Main>
                <ContentWithSidebar>
                    <Content>
                        <ArticleHeader title={page.title} introduction={page.page_introduction.introduction} />

                        <ResponsiveContext.Consumer>
                            {(responsive) =>
                                (responsive === "smallMobile" || responsive === "mobile") && (
                                    <>{(page.children || page.parent) && !venueFirstChildPage && <>{subNavigation}</>}</>
                                )
                            }
                        </ResponsiveContext.Consumer>

                        <Article>
                            <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                            <Newsletter></Newsletter>
                        </Article>
                    </Content>

                    <Sidebar>
                        <ResponsiveContext.Consumer>
                            {(responsive) =>
                                (responsive === "tablet" || typeof window !== "object" || responsive === "desktop" || responsive === "wide") && (
                                    <>{(page.children || page.parent) && <>{subNavigation}</>}</>
                                )
                            }
                        </ResponsiveContext.Consumer>

                        {page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}

                        {page.person_ctas.people && page.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar>
                </ContentWithSidebar>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query NewsletterSignupQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            ...PageSubnav
            ...SidebarCtas
            ...PersonCtas
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateNewsletterSignup)
