// extracted by mini-css-extract-plugin
export var Newsletter = "Newsletter-module--Newsletter--72a3d";
export var checkbox = "Newsletter-module--checkbox--2d749";
export var checkboxes = "Newsletter-module--checkboxes--96954";
export var errorMessage = "Newsletter-module--errorMessage--c0c13";
export var formActions = "Newsletter-module--formActions--cb4f8";
export var formGroup = "Newsletter-module--formGroup--607e5";
export var formGroupErrors = "Newsletter-module--formGroupErrors--63c3f";
export var formGroupSingleCheckbox = "Newsletter-module--formGroupSingleCheckbox--aeb0c";
export var formGroupWithErrors = "Newsletter-module--formGroupWithErrors--6c25c";
export var formGroupWordyLabel = "Newsletter-module--formGroupWordyLabel--61c06";
export var guideText = "Newsletter-module--guideText--13c8d";
export var helperText = "Newsletter-module--helperText--2cb6e";
export var labelSubstitute = "Newsletter-module--labelSubstitute--dc778";
export var radioButton = "Newsletter-module--radioButton--96223";
export var radioButtons = "Newsletter-module--radioButtons--98ddc";
export var required = "Newsletter-module--required--6dd3f";
export var section = "Newsletter-module--section--968e0";