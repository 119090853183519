import React from "react"
import { navigate } from "gatsby-link"

// Styles
import * as styles from "./Newsletter.module.scss"

function encode(data) {
    return JSON.stringify(data)
}

export default function Contact() {
    const [state, setState] = React.useState({})
    const [error, setError] = React.useState(null)
    const [isValid, setIsValid] = React.useState(null)

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const checkValidity = () => {
        return state.WhatsOn === "true" || state.VolunteerOpportunitiesNewsletter === "true" || state.CorporateNewsletter === "true"
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (checkValidity()) {
            setIsValid(true)
            const form = e.target
            fetch("https://database.derbymuseums.org/DesktopModules/Dataware/api/NewsletterSignup/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: encode({
                    "form-name": form.getAttribute("name"),
                    ...state,
                }),
            })
                .then(() => navigate(form.getAttribute("action")))
                .catch((error) => setError(error))
        } else {
            setIsValid(false)
        }
    }

    return (
        <div className={styles.Newsletter}>

            {error && (
                <p className={styles.errorMessage}>
                    Sorry - something has gone wrong. You could try again, or <a href="/about/our-services/contact-us/">contact us directly.</a>
                </p>
            )}

            {isValid === false && <p className={styles.errorMessage}>Please choose at least one newsletter.</p>}
            <form name="newsletter" method="post" action="/thanks/" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="newsletter" />
                <p hidden>
                    <label>Don’t fill this out</label>
                    <input name="bot-field" onChange={handleChange} />
                </p>
                <div className={styles.formGroup}>
                    <label htmlFor="FirstName">First name</label>
                    <input type="text" name="FirstName" id="FirstName" onChange={handleChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="Surname">Surname</label>
                    <input type="text" name="Surname" id="Surname" onChange={handleChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="EmailAddress">Email address</label>
                    <input type="email" name="EmailAddress" id="EmailAddress" onChange={handleChange} required />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="Postcode">Postcode</label>
                    <input type="text" name="Postcode" id="Postcode" onChange={handleChange} />
                </div>
                <div className={styles.guideText}>
                    Derby Museums would love to keep in touch with you, but we also want to make sure we have your consent to do so. Please select your
                    communication preferences below to receive specific content that is relevant and interesting to you. You will be able to withdraw your
                    consent at any time and Derby Museums will never share your data with third parties.
                </div>
                <div className={`${styles.formGroup} ${styles.formGroupSingleCheckbox}`}>
                    <label htmlFor="WhatsOn">
                        <strong>What’s On at Derby Museums</strong>
                        <br /> I would like to receive information about events, exhibitions, fundraising campaigns and family activities.
                    </label>
                    <input type="checkbox" name="WhatsOn" id="WhatsOn" value="true" onChange={handleChange} />
                </div>
                <div className={`${styles.formGroup} ${styles.formGroupSingleCheckbox}`}>
                    <label htmlFor="VolunteerOpportunitiesNewsletter">
                        <strong>Volunteer Opportunities Newsletter</strong>
                        <br /> I would like to receive information about volunteering activities and opportunities.
                    </label>
                    <input type="checkbox" name="VolunteerOpportunitiesNewsletter" id="VolunteerOpportunitiesNewsletter" value="true" onChange={handleChange} />
                </div>
                <div className={`${styles.formGroup} ${styles.formGroupSingleCheckbox}`}>
                    <label htmlFor="CorporateNewsletter">
                        <strong>Corporate Newsletter</strong>
                        <br /> I would like to receive information about corporate events, venue hire, corporate volunteering, etc.
                    </label>
                    <input type="checkbox" name="CorporateNewsletter" id="CorporateNewsletter" value="true" onChange={handleChange} />
                </div>
                <div className={styles.formActions}>
                    <button type="submit">Send</button>
                </div>
            </form>
        </div>
    )
}
